var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[(!_vm.canReportQuantity)?_c('v-card',{staticClass:"mb-6",attrs:{"rounded":"lg"}},[_c('v-card-text',{staticClass:"d-flex align-center",staticStyle:{"column-gap":"20px"}},[_c('cz-icon',{attrs:{"size":32,"src":_vm.mdiAlertOutline,"color":"negative"}}),_c('div',{staticClass:"text-body-1 font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('vendor.orderSubmissions.quantityReportAlertMessage'))+" ")])],1)],1):_vm._e(),_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"gap":"25px"},style:({
      opacity: !_vm.canReportQuantity ? 0.5 : 1
    }),attrs:{"flat":""}},[_c('v-card-text',[_c('cz-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"hide-default-footer":"","columns":_vm.submissionItemsTableColumns,"items":_vm.submission.submissionItems},scopedSlots:_vm._u([{key:"item.supplyDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDateToDisplay(item.orderItem.supplyDate, 'dd/MM/yyyy'))+" ")]}},(_vm.submission)?{key:"item.pricePerUnit",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency( item.orderItem.pricePerUnit, _vm.submission.currency ))+" ")]}}:null,(_vm.submission)?{key:"item.totalAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency( item.orderItem.totalAmount, _vm.submission.currency ))+" ")]}}:null,{key:"item.currentQuantity",fn:function({ item }){return [_c('validation-provider',{attrs:{"rules":`max_value:${
              _vm.isCreditInvoice
                ? item.orderItem.quantityReported
                : item.orderItem.quantityLeft
            }`,"name":item.orderItem.catalogItem.itemName},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(
                (_vm.isCreditInvoice && item.orderItem.quantityReported > 0) ||
                (!_vm.isCreditInvoice && item.orderItem.quantityLeft) > 0
              )?_c('cz-input',{staticStyle:{"width":"75px"},attrs:{"dense":"","hide-details":!errors || !errors.length,"type":"number","error-messages":errors,"readonly":_vm.readonly,"placeholder":_vm.$t('vendor.orderSubmissions.enterQuantity'),"disabled":item.quantityLeft === 0},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}):_vm._e()]}}],null,true)})]}},{key:"item.currentAmount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatAmountWithCurrency( parseFloat(item.quantity || 0) * parseFloat(item.orderItem.pricePerUnit), _vm.submission.currency ))+" ")]}},{key:"footer",fn:function(){return [_c('v-footer',{staticClass:"d-flex justify-end mt-4"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c('div',{staticClass:"d-flex align-center justify-space-between text-body-1 font-weight-semibold",staticStyle:{"gap":"20px"}},[_c('div',[_vm._v(_vm._s(_vm.$t('common.total'))+":")]),_c('span',[_vm._v(_vm._s(_vm.formatAmountWithCurrency( _vm.currentSubmissionAmount, _vm.submission.currency )))])]),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex align-center justify-space-between text-body-1 font-weight-semibold",staticStyle:{"gap":"20px"}},[_c('div',[_vm._v(_vm._s(_vm.$t('common.vat'))+":")]),_c('span',[_vm._v(_vm._s(_vm.formatAmountWithCurrency( _vm.currentSubmissionAmount * _vm.vendorVat, _vm.submission.currency )))])]),_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex align-center justify-space-between text-body-1 font-weight-semibold",staticStyle:{"gap":"20px"}},[_c('div',[_vm._v(_vm._s(_vm.$t('common.totalWithVat'))+":")]),_c('span',[_vm._v(_vm._s(_vm.formatAmountWithCurrency( _vm.currentSubmissionTotalAmount, _vm.submission.currency )))])])],1)])]},proxy:true}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }